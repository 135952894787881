export default {
  add_survey: "Dodaj badanie",
  cancel: "Anuluj",
  back: "Wróć",
  next: "Przejdź dalej",
  create_success: "Badanie utworzone pomyślnie.",
  save: "Zapisz",
  save_changes: "Zapisz zmiany",
  step1: {
    title: {
      create: "Dodaj badanie - Utwórz nowe badanie",
      edit: "Edytuj dane firmy",
    },
    subtitle: {
      create: "Utwórz nowe badanie",
      edit: "Edytuj dane firmy",
    },
    name: "Nazwa firmy",
    team: "Nazwa zespołu",
    start_date: "Początek badania",
    end_date: "Koniec badania",
    team_size: "Wielkość zespołu",
    company_size: "Wielkość firmy",
    branch: "Branża",
    study_type: "Typ badania",
    manager_first_name: "Imię managera",
    manager_last_name: "Nazwisko managera",
    manager_email: "Adres e-mail managera",
  },
  step2: {
    title: {
      create: "Dodaj handlowców - Przypisz handlowców do badania",
      additional: "Zarządzanie handlowcami",
      edit: "Edytuj handlowców",
    },
    subtitle: "Przypisz handlowców",
    name: "Imię",
    last_name: "Nazwisko",
    email: "Adres e-mail",
    add_respondent: "Dodaj handlowca",
    addnext_respondent: "Dodaj kolejnego handlowca",
    import: "Importuj",
    delete_title: "Czy napewno chcesz usunąć handlowca?",
    empty: "Brak przypisanych handlowców",
  },
};
