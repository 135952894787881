export default {
  title: "Cele uczestnika",
  add: "Dodaj",
  delete: "Czy na pewno chcesz usunąć ten cel?",
  delete_parent: "Czy na pewno chcesz usunąć ten cel i jego podcele?",
  complete: "Oznacz jako wykonane",
  incomplete: "Oznacz jako niewykonane",
  add_subgoal: "Dodaj podcel",
  edit: "Edytuj",
  save: "Zapisz",
  del: "Usuń",
};
