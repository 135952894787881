<template>
  <div
    class="wrapper wrapper-full-page"
    :class="[{ 'nav-open': $sidebar.showSidebar }, { rtl: $route.meta.rtlActive }]"
  >
    <fade-transition mode="out-in">
      <div
        class="loader-overlay fixed inset-0 z-50 flex justify-center items-center"
        v-if="isLoading"
      >
        <img :src="require('@/assets/images/logo.svg')" class="loader self-center" />
      </div>
    </fade-transition>
    <notifications></notifications>
    <side-bar>
      <mobile-menu></mobile-menu>
    </side-bar>
    <top-navbar></top-navbar>
    <BreadCrumbs v-if="!isDashboardRoute" />
    <zoom-center-transition :duration="300" mode="out-in">
      <router-view></router-view>
    </zoom-center-transition>
    <notifications position="bottom right" group="notifications" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import TopNavbar from "./TopNavbar.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import { ZoomCenterTransition, FadeTransition } from "vue2-transitions";
import BreadCrumbs from "./BreadCrumbs";

export default {
  components: {
    BreadCrumbs,
    TopNavbar,
    MobileMenu,
    ZoomCenterTransition,
    FadeTransition,
  },
  computed: {
    ...mapState("layout", ["isLoading"]),
    isDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;

.md-button {
  border-radius: 6px;
  font-size: 14px;
  &.outlined {
    background-color: transparent !important;
  }
  &:disabled,
  &[disabled] {
    background-color: #acacac !important;
  }
}

.swal2-actions {
  button {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(2px);
}

$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.loader {
  width: 180px;
  height: 44px;
  animation: pulse 3s linear infinite;
  &-overlay {
    top: 135px;
    background-color: #eee;
    /*animation: show 300ms linear;*/
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}
.overlay {
  background: rgba(0, 0, 0, 0.3);
}
</style>
