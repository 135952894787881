import actions from './actions';
import mutations from './mutations';
import state from './state';

export default {
  actions,
  mutations,
  state,
  namespaced: true,
}
