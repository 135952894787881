import api from "../../helpers/api";

export default {
  async getExamRounds({ commit }, { examId, payload }) {
    const response = await api.get(`/coach/exams/${examId}/round`, { params: payload });
    const data = response.data.data;

    commit("setExamRounds", data);
  },

  async updateExamRound(store, { examId, roundId, payload }) {
    await api.put(`/coach/exams/${examId}/round/${roundId}`, payload);
  },

  async addExamRoundComment(store, { roundId, payload }) {
    await api.post(`/coach/comments/${roundId}`, payload);
  },

  async updateExamRoundComment(store, { roundId, commentId, payload }) {
    await api.put(`/coach/comments/${roundId}/${commentId}`, payload);
  },

  async createExamRound(store, { examId, payload }) {
    await api.post(`/coach/exams/${examId}/round`, payload);
  },

  async startExamRound(store, { examId, payload }) {
    await api.post(`/coach/exams/${examId}/start`, payload);
  },
};
