import auth from "./auth";
import exam from "./exam";
import participant from "./participant";
import respondent from "./respondent";
import section from "./section";
import examRound from "./examRounds";
import participantGoals from "./participant-goals";
import participantNotes from "./participant-notes";
import examRoundComments from "./exam-round-comments";
import layout from "./layout";
import participantNoteAttachments from "./participant-note-attachments";
import studyTypes from "./study-types";
import studyRespondGroups from "./study-respond-groups";

export default {
  auth,
  exam,
  participant,
  respondent,
  section,
  examRound,
  participantGoals,
  participantNotes,
  examRoundComments,
  layout,
  participantNoteAttachments,
  studyTypes,
  studyRespondGroups,
};
