export default {
  first_name: "Imię",
  last_name: "Nazwisko",
  email: "Adres email",
  status: "Status",
  actions: "Akcje",
  save: "Powróć do badania",
  add: "Dodaj",
  delete: {
    title: "Czy na pewno chcesz usunąć tego handlowca?",
  },
  back: {
    title: "Czy na pewno chcesz cofnać?",
    text: "Wpisani handlowcy nie zostaną zapisani",
    confirmButtonText: "Cofnij",
  },
  create: "Dodawanie handlowców",
  tooltips: {
    reminded: "Wysłano przypomnienie",
    new: "Nowy",
    invalid: "Błędny adres e-mail",
    fulfilled: "Wypełnił badanie",
    started: "Rozpoczął badanie",
    correct: "Otrzymał wiadomość",
  },
  delete_group: {
    title: 'Czy na pewno chcesz usunąć grupę "{group}"',
    text: "Respondenci należący do tej grupy również zostana usunięci.",
  },
  edit_group: "Edytuj grupę handlowców",
  save_group: "Zapisz",
  add_group_label: "Wpisz nazwę nowej grupy...",
  add_group: "Dodaj",
};
