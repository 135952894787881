<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="flex items-center my-6 mx-3">
        <router-link :to="{ name: 'Dashboard' }">
          <img :src="require('@/assets/images/logo.svg')" width="180px" alt="" />
        </router-link>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <div class="topbar__actions">
            <md-button @click="isExtending = !isExtending" class="md-button md-just-icon md-simple">
              <md-icon>
                <md-tooltip md-direction="bottom">
                  {{ $t("support.tooltip") }}
                </md-tooltip>
                help
              </md-icon>
            </md-button>
            <span class="topbar__user">{{ user.first_name }} {{ user.last_name }}</span>
            <md-menu md-align-trigger md-direction="bottom-end">
              <md-button class="md-button md-just-icon md-simple" md-menu-trigger>
                <md-icon class="ml-1" :md-src="require('@/assets/images/icons/user.svg')"></md-icon>
              </md-button>
              <md-menu-content>
                <md-menu-item @click="handleLogout">{{ $t("auth.logout") }}</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="isExtending">
      <template slot="header">
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="isExtending = false"
        >
          <md-icon>close</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <div class="flex flex-col justify-center items-center">
          <img :src="require('@/assets/images/conversation.svg')" class="w-1/3 pb-6" alt="support" />
          <p>{{ $t("support.title") }}</p>
        </div>
      </template>
      <template slot="footer">
        <a class="text-center font-bold" href="mailto:support@salesscanner.pl"
          >support@salesscanner.pl</a
        >
      </template>
    </Modal>
  </md-toolbar>
</template>
<script>
import { Modal } from "@/components";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isExtending: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    async handleLogout() {
      await this.logout();
      this.$router.push({ name: "Auth.Login" });
    },
  },
};
</script>
<style lang="scss" scoped>
.topbar {
  &__actions {
    display: flex;
    align-items: center;
  }
  &__user {
    padding: 0 20px;
    font-size: 16px;
  }
}
</style>
