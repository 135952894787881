export default {
  comment_title: "Tutaj możesz wpisać komentarz do raportu.",
  comment_subtitle: "Część {roundNumber}, sekcja {sectionNumber}",
  comment_chart: "Zbiorcze podsumowanie wszystkich odpowiedzi w tej sekcji",
  content_placeholder: "To jest miejsce do wpisania komentarza i pytań do uczestnika",
  no_of_answers: "liczba odpowiedzi",
  next: "Kolejna sekcja",
  prev: "Poprzednia sekcja",
  report: "Zakończ i wygeneruj raport",
  alert: {
    title: "Koniec sekcji",
    text: "Czy chcesz zapisać komentarz i przejść dalej?",
  },
  comment_saved: "Zapisano komentarz!",
  select_section: "Wybierz sekcję",
};
