import api from '@/helpers/api';

export default {
  async getStudyTypes({ commit }, payload) {
    const response = await api.get('coach/study-types', { params: payload });

    const data = response.data.data;

    commit('setStudyTypes', data);
  }
}