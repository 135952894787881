<template>
  <div class="container m-auto">
    <div class="w-full">
      <DataTable
        :title="$route.meta.name"
        icon="shopping_cart"
        :is-loading="globalLoading"
        :columns="tableColumns"
        :table-data="exams"
        :pagination="pagination"
        class="w-full"
        @page-change="changePage"
        @filter="filterData"
        @clear-filters="clearFilters"
      >
        <template slot="rowActions" slot-scope="{ item }">
          <md-button class="md-info" :to="{ name: 'Exam.Show', params: { id: item.id } }">
            <md-icon>visibility</md-icon>
            <div class="hidden lg:block">{{ $t("exam.see") }}</div>
          </md-button>
          <md-menu>
            <md-button md-menu-trigger class="md-simple md-icon-button">
              <md-icon>more_vert</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item
                v-if="!item.is_draft"
                :to="{ name: 'Respondents.Additional', params: { id: item.id } }"
              >
                {{ $t("exam_round.manage_respondents") }}
              </md-menu-item>
              <md-menu-item
                v-if="!item.is_draft"
                :to="{ name: 'Exam.Show', params: { id: item.id, openExtendModal: true } }"
              >
                {{ $t("exam_round.edit_end_date") }}
              </md-menu-item>
              <md-menu-item
                v-if="item.is_report_ready"
                :to="{ name: 'Exam.Results', params: { examId: item.id, roundId: 1 } }"
              >
                {{ $t("exam.report") }}
              </md-menu-item>
              <md-divider></md-divider>
              <md-menu-item class="md-list-item-router" @click="askForDelete(item)">
                <a>{{ $t("exam.delete") }}</a>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
          <md-button v-if="item.is_report_ready" class="md-info">
            <md-icon>receipt</md-icon>
            {{ $t("exam.report") }}
          </md-button>
        </template>
      </DataTable>
      <md-button class="md-raised md-warning float-right" :to="{ name: 'Exam.Create' }">
        <md-icon>add</md-icon>
        {{ $t("survey.add_survey") }}
      </md-button>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { deleteAlert } from "../../helpers/swal";
import { mapState, mapActions } from "vuex";
import DataTable from "../../components/Tables/DataTable";
import i18n from "@/i18n";

export default {
  name: "DashboardIndex",

  components: {
    DataTable,
  },

  data() {
    return {
      globalLoading: true,
      currentPage: 1,
      tableColumns: [
        {
          title: this.$t("participant.name"),
          name: "participant.name",
          calculatedValue(item) {
            return (
              item.participant.name +
              (item.participant.team != "" ? " (" + item.participant.team + ")" : "")
            );
          },
          filterable: true,
        },
        {
          title: this.$t("manager.name"),
          name: "manager.name",
          calculatedValue(item) {
            return item.manager.first_name + " " + item.manager.last_name;
          },
        },
        {
          title: this.$t("exam.status"),
          calculatedValue(item) {
            if (item.rounds.length) {
              return item.rounds[item.rounds.length - 1].status;
            } else {
              return i18n.t("exam.statuses.draft");
            }
          },
          name: "rounds.status",
          filterable: true,
        },
        {
          title: this.$t("exam.end_date"),
          calculatedValue(item) {
            if (item.rounds) {
              return item.rounds.reduce((prev, round) => {
                return prev + round.end_date + "<br />";
              }, "");
            }
          },
        },
        {
          title: this.$t("exam.progress"),
          calculatedValue(item) {
            const rounds = item.rounds;
            if (rounds.length) {
              return rounds.reduce((prev, round) => {
                const stats = `${round.fulfilled_respondents}/${round.total_respondents}`;
                const managerFlag = round.is_manager_fulfilled ? " + M" : "";

                if (round.fulfilled_respondents / round.total_respondents == 1) {
                  return `${prev} ${stats}${managerFlag}
                      <div class="hidden lg:block md-progress-bar md-determinate md-green">
                          <div class="md-progress-bar-fill" style="width: ${
                            (round.fulfilled_respondents / round.total_respondents) * 100
                          }%;"></div>
                      </div>`;
                } else {
                  return `${prev} ${stats}${managerFlag}
                      <div class="hidden lg:block md-progress-bar md-determinate md-theme-default">
                          <div class="md-progress-bar-fill" style="width: ${
                            (round.fulfilled_respondents / round.total_respondents) * 100
                          }%;"></div>
                      </div>`;
                }
              }, "");
            }
            return "";
          },
        },
      ],
      filters: {},
      filterMethod: null,
    };
  },

  computed: {
    ...mapState("exam", ["exams", "pagination"]),
  },

  methods: {
    ...mapActions("exam", ["getExams", "deleteExam"]),

    async getData(params = {}) {
      const defaultParams = {
        page: this.currentPage,
        limit: 10,
        filters: this.filters,
      };
      const payload = Object.assign(defaultParams, params);
      await this.getExams(payload);
    },

    async changePage(value) {
      this.globalLoading = true;
      this.currentPage = value;
      await this.getData();
      this.globalLoading = false;
    },

    async askForDelete(exam) {
      const { dismiss } = await deleteAlert({
        title: this.$t("exam.delete_confirmation", {
          firstName: exam.participant.first_name,
          lastName: exam.participant.last_name,
        }),
      });
      if (!dismiss) {
        await this.deleteExam(exam.id);
        await this.getData();
      }
    },

    async filterData(data) {
      let [key, value] = data.split(":");
      if (value.length > 0) {
        this.filters[key] = value;
      } else {
        this.filters[key] = undefined;
      }
      await this.filterMethod();
    },
    async clearFilters() {
      this.filters = {};
      await this.getData();
    },
  },

  async created() {
    this.globalLoading = true;
    if (window.innerWidth < 992) {
      this.tableColumns = this.tableColumns.slice(0, 1).concat(this.tableColumns.slice(-1));
    }
    await this.getData();
    this.$store.commit("participant/clearParticipant");
    this.globalLoading = false;

    this.filterMethod = debounce(async () => {
      this.globalLoading = true;
      await this.getData();
      this.globalLoading = false;
    }, 500);
  },
};
</script>

<style>
.disabled > a,
.disabled > a > div {
  cursor: not-allowed !important;
}

.disabled,
.disabled > a:hover {
  background-color: #e0e0e0 !important;
}

.disabled .router-link-active > div {
  color: #000000 !important;
}

.card-icon {
  display: none;
}
</style>
