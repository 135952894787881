export default {
  exam_for: "Badanie dla",
  dates: "Data badania",
  type: "Typ badania",
  status: "Status",
  progress: "Odpowiedzi handlowców",
  manage_respondents: "Zarządzaj handlowcami",
  edit_end_date: "Edytuj datę zakończenia",
  see_results: "Raport - wyniki",
  download_results: "Pobierz wyniki do XLSX",
  see_report: "Podgląd raportu",
  send_report: {
    first: "Wyślij raport",
    second: "użytkownikowi",
  },
  statuses: {
    in_progress: "W trakcie",
    waiting: "Oczekuje",
    done: "Zakończone",
    report_ready: "Raport gotowy",
    report_sent: "Wysłano raport",
  },
  types: {
    first: "Badanie właściwe",
    second: "Ewaluacja",
  },
  select_extension_date: "Wybierz datę",
  extend: "Zmień datę",
  select_start_date: "Wybierz datę początkową",
  select_end_date: "Wybierz datę końcową",
  create: "Utwórz",
  create_second_round: "Rozpocznij ewaluację",
  start_exam_title: "Czy na pewno chcesz utworzyć badanie?",
  start_exam_text:
    "Nazwa firmy: {name} <br />" +
    "Imię i nazwisko managera: {managerName} <br />" +
    "Trwanie badania: {startDate} - {endDate}<br />" +
    "Liczba handlowców: {number}",
  exam_start: "Rozpocznij badanie",
  not_enough_sections_title: "Nie wybrano żadnych sekcji!",
  not_enough_sections_text: "Aby kontynuować musisz wybrać co najmniej jedną sekcję.",
  explain_dates:
    "Ustaw datę rozpoczęcia i zakończenia badania. Rekomendujemy, aby okres badania obejmował minimum 10 dni.",
  explain_exam_end:
    "* Jeśli wszyscy handlowcy zakończą egzamin przed datą zakończenia, egzamin zostanie automatycznie zakończony.",
  generate_report_first: "Wygeneruj",
  generate_report_second: "i otwórz raport",
  refresh_first: "Raport jest",
  refresh_second: "generowany,",
  refresh_third: "Odśwież stronę",
  enter_comments: "Wpisz komentarze",
};
