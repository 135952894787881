import DashboardLayout from "../pages/Dashboard/Layout/DashboardLayout.vue";

const Create = () => import(/* webpackChunkName: "respondent" */ "../pages/Respondents/Create.vue");
import i18n from "../i18n";
const Additional = () =>
  import(/* webpackChunkName: "respondent" */ "../pages/Respondents/Additional");

export default {
  path: "/respondents",
  component: DashboardLayout,
  redirect: "/respondents/create",
  name: "Respondents",
  children: [
    {
      path: "create/:id",
      name: "Respondents.Create",
      component: Create,
      meta: {
        name: i18n.t("survey.step2.title.create"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            name: "Cetus",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            disabled: true,
          },
          {
            name: i18n.t("respondents.create"),
            link: "Respondents.Create",
          },
        ],
      },
    },
    {
      path: "additional/:id",
      name: "Respondents.Additional",
      component: Additional,
      meta: {
        name: i18n.t("survey.step2.title.additional"),
        breadcrumbs: [
          {
            name: i18n.t("dashboard.dashboard"),
            link: "Dashboard",
          },
          {
            name: "Cetus",
            disabled: true,
          },
          {
            nameGetter: "participant/getParticipantName",
            link: "Exam.Show",
          },
          {
            name: i18n.t("respondents.create"),
            link: "Respondents.Additional",
          },
        ],
      },
    },
  ],
};
